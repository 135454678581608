import styled from 'styled-components';

interface StylesProps {
  bgColor?: string;
  height: string | number;
  border?: string;
  fontSize?: string | number;
  color?: string;
}

export const Wrapper = styled.div<StylesProps>`
  padding: 20px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : props.theme.palette.primary.color};
  border-radius: 6px;
  box-shadow: 0px 2px 6px #0000000a;
  height: ${(props) => props.height};
  border: ${(props) => (props.border ? props.border : 'none')};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.span<StylesProps>`
  color: ${(props) =>
    props.color ? props.color : props.theme.palette.text.primary};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16pt')};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
