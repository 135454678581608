import { lineReceipt, lineUser } from '@l_ine/light-svg-icons';

/**
 * Estas são as configurações que aparecem no painel de configurações.
 * Todas elas devem possuir links pois é através deles que o sistema
 * de pesquisa funciona. O link será composto da
 * base (propriedade url) concatenado pelo valor 'link'. Recomendamos que quando
 * utilizar abas em alguma configuração faça que a mesma seja exibida de acordo
 * com um parâmetro da url para dessa forma conseguir criar um link direto.
 */

export const configurationPages = [
  {
    key: 0,
    icon: lineUser,
    name: 'usersData',
    title: 'Dados do usuário',
    links: [
      { label: 'Informações gerais', link: '' },
      { label: 'Alterar senha', link: '' },
    ],
    url: 'dados-usuario',
  },
  {
    key: 1,
    icon: lineReceipt,
    name: 'integrationsData',
    title: 'Integrações',
    links: [
      { label: 'Dados de seguro', link: '?tab=seguro' },
      { label: 'Averbação automática', link: '?tab=seguro' },
      { label: 'CIOT', link: '?tab=ciot' },
    ],
    url: 'dados-integracoes',
  },
];
