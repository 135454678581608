import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: auto;
`;

export const FooterWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 50px;
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${(props) => props.theme.palette.primary.color};
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const BannerImg = styled.img`
  height: auto;
`;

export const BannerLogo = styled.img`
  height: auto;
  max-width: 200px;
`;

export const BannerText = styled.h3`
  width: 290px;
  color: white;
  font-size: 24px;
  line-height: 36px;
  font-weight: normal;
  margin-top: 16px;

  > span {
    background: black;
    color: white;
    padding: 4px;
    border-radius: 4px;
    margin-left: 8px;
  }
`;
