import React from 'react';

//UI
import * as styled from './index.style';
import { Button, Typography, LIcon, Breadcrumbs } from '@l_ine/core';
import { isArray } from 'lodash';
import Link from 'next/link';
import { lineChevronLeft } from '@l_ine/regular-svg-icons';
import { useRouter } from 'next/router';

interface PageHeaderProps {
  title: string | React.ReactNode;
  breadcrumbs?: string | JSX.Element | { label: string; link?: string }[];
  primaryButtonTitle?: string | React.ReactNode;
  primaryButtonClick?(): void;
  primaryButtonIcon?: any;
  secondaryButtonTitle?: string | React.ReactNode;
  secondaryButtonClick?(): void;
  secondaryButtonIcon?: any;
  terciaryButtonClick?(): void;
  terciaryButtonIcon?: any;
  terciaryButtonTitle?: string;
  backButton?: boolean;
}

function PageHeader(props: PageHeaderProps) {
  const router = useRouter();

  const {
    breadcrumbs,
    title,
    primaryButtonClick,
    primaryButtonIcon,
    primaryButtonTitle,
    secondaryButtonClick,
    secondaryButtonTitle,
    secondaryButtonIcon,
    terciaryButtonClick,
    terciaryButtonTitle,
    terciaryButtonIcon,
    backButton,
  } = props;

  return (
    <styled.Root>
      <styled.PageTitle>
        <Typography variant='titleXXS'>
          <Breadcrumbs>
            {!isArray(breadcrumbs)
              ? breadcrumbs
              : breadcrumbs.map(link => (
                  <Link key={link.label} href={link.link || '#'}>
                    <a>{link.label}</a>
                  </Link>
                ))}
          </Breadcrumbs>
        </Typography>
        <div
          onClick={() => {
            if (backButton) {
              router.replace(router.pathname.substring(0, router.pathname.lastIndexOf('/')));
            }
          }}>
          <Typography
            variant='titleMD'
            noWrap
            style={{ alignItems: 'center', display: 'flex', cursor: 'pointer' }}>
            {backButton && <LIcon icon={lineChevronLeft} size='32px' />}
            {title}
          </Typography>
        </div>
      </styled.PageTitle>
      <div>
        {!!terciaryButtonIcon && (
          <>
            <Button
              data-testid='pageHeader'
              variant='dashed'
              onClick={terciaryButtonClick}
              size='md'
              style={{ marginRight: 15 }}
              startIcon={<LIcon icon={terciaryButtonIcon} style={{ fontSize: 15 }} />}>
              {terciaryButtonTitle}
            </Button>
          </>
        )}
        {!!secondaryButtonTitle && (
          <>
            <Button
              data-testid='pageHeader'
              variant='contained'
              onClick={secondaryButtonClick}
              size='sm'
              startIcon={<LIcon icon={secondaryButtonIcon} style={{ fontSize: 15 }} />}
              style={{ marginRight: 15 }}>
              {secondaryButtonTitle}
            </Button>
          </>
        )}
        {!!primaryButtonIcon && (
          <Button
            data-testid='pageHeader'
            variant='contained'
            bgColor='primary'
            onClick={primaryButtonClick}
            size='md'
            startIcon={<LIcon icon={primaryButtonIcon} style={{ fontSize: 15 }} />}>
            {primaryButtonTitle}
          </Button>
        )}
      </div>
    </styled.Root>
  );
}

export default PageHeader;
