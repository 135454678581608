import React from 'react';

import { connect } from 'react-redux';
import appActions from '../../redux/app/actions';

import { Notification as LineNotification } from '@l_ine/core';

import { NotificationProps } from './index.d';

import { useRouter } from 'next/router';
import { usePathname } from '@l_ine/hooks';

const Notification: React.FC<NotificationProps> = props => {
  const router = useRouter();
  const pathname = usePathname({ router });

  const { activated, time, title, message } = props.snackbar;

  const variant = props.snackbar.variant || 'success';
  const xPosition = props.snackbar.positionX || 'right';
  const yPosition = props.snackbar.positionY || 'top';

  const handleClose = () => {
    props.toggleSnackBar({
      activated: false,
      time: 3000,
      variant: 'success',
    });
  };

  return (
    <LineNotification
      autoHideDuration={time}
      data-testid={`${pathname}--notification`}
      {...{ variant, title, message }}
      open={activated}
      floating={true}
      showCloseButton={true}
      positionX={xPosition}
      positionY={yPosition}
      onClose={() => handleClose()}
    />
  );
};

const { toggleSnackBar } = appActions;

export default connect(
  (state: any) => ({
    snackbar: state.App.snackbar,
  }),
  { toggleSnackBar }
)(Notification);
