import React from 'react';

import { ModalDialog } from '@l_ine/core';

import { connect } from 'react-redux';

import appActions from '../../redux/app/actions';

import { DialogProps } from './index.d';

const Dialog: React.FC<DialogProps> = (props) => {
  const handleClose = () => {
    props.toggleDialog({
      activated: false,
      title: '',
      content: '',
      actions: null,
      onSubmit: null,
      initialValues: null,
      dialogContentProps: {},
    });
  };

  const { children, activated, onSubmit, dialogProps } = props.dialog;
  return (
    <ModalDialog
      open={activated}
      onClose={() => handleClose()}
      onSubmit={onSubmit}
      {...dialogProps}
    >
      {children}
    </ModalDialog>
  );
};

const { toggleDialog } = appActions;

export default connect(
  (state: any) => ({
    dialog: state.App.dialog,
  }),
  { toggleDialog }
)(Dialog);
