import { CircularProgress } from '@l_ine/core';
import React from 'react';
import * as styled from './index.style';

const Loading = () => {
  return (
    <styled.Wrapper>
      <styled.PaperLoading>
        <CircularProgress size={50} />
      </styled.PaperLoading>
    </styled.Wrapper>
  );
};

export default Loading;
