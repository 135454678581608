import React from 'react';
import { Form } from 'react-final-form';

import { Grid } from '@l_ine/core';
const loginLogo = 'static/svgs/loginLogo.svg';
import * as styled from './index.style';

import { Typography } from '@l_ine/core';

import { utils } from '@l_ine/core';
import { siteConfig } from '../../config';

import { FormApi, SubmissionErrors, ValidationErrors } from 'final-form';

interface LayoutProps {
  bannerSrc?: string;
  logo?: string | null;
  bannerComponent?: React.ReactElement | null;
  bannerAlt?: string;
  form: React.ReactElement;
  initialValues?: any;
  onSubmit: (
    values: any,
    form: FormApi<any, any>,
    callback?: (errors?: SubmissionErrors) => void
  ) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
  validate?: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const Layout: React.FC<LayoutProps> = props => {
  const { width } = utils.useWindowSize();
  const {
    bannerAlt,
    bannerSrc,
    form,
    logo,
    onSubmit,
    initialValues,
    validate,
    spacing,
    bannerComponent,
  } = props;
  const footerText = siteConfig.footerText;

  React.useEffect(() => {
    document.body.style.backgroundColor = '#fff';

    return () => {
      document.body.style.backgroundColor = 'rgba(110, 127, 128, 0.05)';
    };
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={formProps => {
        return (
          <form onSubmit={formProps.handleSubmit} noValidate={true}>
            <Grid container spacing={spacing ? spacing : 0} style={{ height: '100vh' }}>
              {width > 960 && (
                <Grid item xs={12} md={6}>
                  <styled.Container>
                    <styled.BannerLogo src={logo || loginLogo} alt='Solarfy' />
                    {bannerComponent && React.isValidElement(bannerComponent) ? (
                      React.cloneElement(bannerComponent)
                    ) : (
                      <styled.BannerImg src={bannerSrc} alt={bannerAlt} />
                    )}
                  </styled.Container>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                  }}>
                  <styled.FormWrapper>
                    {React.cloneElement(form, { ...formProps })}
                  </styled.FormWrapper>
                  <styled.FooterWrapper>
                    <Typography
                      variant='bodySM'
                      noWrap={true}
                      style={{
                        color: 'rgba(75,75,75,0.5)',
                      }}>
                      {footerText}
                    </Typography>
                  </styled.FooterWrapper>
                </div>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default Layout;
