import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
`;

export const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
